export function getStartingWordsToAvoid(): Map<string, number> {
    let values = {
        "a": 50,
        "and": 0,
        "to": 0,
        "at": 0,
        "it": 0,
        "its": 50,
        "of": 0,
        "or": 0,
        "in": 50,
        "is": 0,
        "we": 50,
        "when": 0,
        "while": 50,
        "what": 50,
        "which": 50,
        "are": 0,
        "her": 0,
        "his": 0,
        "but": 0,
        "for": 0,
        "had": 50,
        "has": 50,
        "him": 0,
        "the": 50,
        "she": 0,
        "was": 50,
        "as": 50,
        "be": 50,
        "you": 50,
        "not": 0,
        "can": 0,
        "been": 50,
        "being": 75,
        "because": 50,
        "before": 50,
        "by": 50,
        "so": 50,
    };

    return new Map(Object.entries(values));
}

export function getEndingWordsToAvoid(): Map<string, number> {
    let values = {
        "i": 0,
        "a": 0,
        "have": 0,
        "if": 0,
        "it": 0,
        "and": 0,
        "to": 50,
        "of": 0,
        "but": 0,
        "is": 0,
        "are": 0,
        "the": 0,
        "or": 0,
        "in": 50,
        "as": 50,
        "by": 60,
        "we": 0,
        "at": 0,
        "his": 0,
        "had": 0,
        "he": 0,
        "so": 50,
    };

    return new Map(Object.entries(values));
}
